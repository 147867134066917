/** @jsx jsx */
import { Grid, Heading, Box, Text, Styled, jsx, Flex } from 'theme-ui';
import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import TeamBlockContent from '../components/TeamBlockContent';

const AboutPage = ({ location, data }) => (
  <Layout location={location}>
    <SEO title="Qui sommes-nous ?" />
    <Box
      sx={{
        gridColumnStart: [1, null, null, 2],
        gridColumnEnd: [2, 3, 5, 12],
        mb: 5,
      }}
    >
      <Text sx={{ fontSize: [4, 6], color: 'black' }}>
        &ldquo;Mindkit veut donner le pouvoir à chacun.e de{' '}
        <strong>
          trouver la meilleure façon de booster sa forme mentale ainsi que de
          rendre accessible le <i>mental fitness</i> à tou.te.s
        </strong>
        &rdquo;
      </Text>
    </Box>
    {/* <Grid
      columns={[6, 12]}
      gap={[3, 5]}
      sx={{
        gridColumnStart: [1],
        gridColumnEnd: [2, 3, 5, 13],
        mb: [4, 5],
      }}
    >
      <GatsbyImage
        sx={{ gridColumnStart: [1], gridColumnEnd: [4, 5], borderRadius: 1 }}
        fluid={data.aboutImageOne.childImageSharp.fluid}
      />
      <GatsbyImage
        sx={{
          gridColumnStart: [4, 5],
          gridColumnEnd: [7, 10],
          borderRadius: 1,
        }}
        fluid={data.aboutImageTwo.childImageSharp.fluid}
      />
      <GatsbyImage
        sx={{
          gridColumnStart: [10],
          gridColumnEnd: [13],
          display: ['none', 'block'],
          borderRadius: 1,
        }}
        fluid={data.aboutImageThree.childImageSharp.fluid}
      />
    </Grid> */}
    <Box
      sx={{ gridColumnStart: [1, null, 2, 4], gridColumnEnd: [2, 3, 4, 10] }}
    >
      <Heading sx={{ mb: 3 }}>Le début de l’histoire...</Heading>
      <Text sx={{ mb: 3 }}>
        Rewind to 2018 où Katherine s’est posé la question suivante :{' '}
        <i>
          “Et si on luttait contre le stress en entretenant notre forme mentale
          au même titre que notre forme physique ?”
        </i>{' '}
      </Text>
      <Text sx={{ mb: 3 }}>
        Et oui, elle avait fait le lien entre deux choses hyper simples :
      </Text>
      <Text sx={{ mb: 3 }}>
        <span role="img" aria-label="pointing up emoji">
          👆
        </span>{' '}
        Si nous savons que le sport, la méditation et le yoga ont un effet hyper
        positif sur notre moral et nous permettent de réduire notre stress,
      </Text>
      <Text sx={{ mb: 3 }}>
        <span role="img" aria-label="peace sign emoji">
          ✌️
        </span>{' '}
        Et que nous savons que verbaliser est indispensable pour mieux gérer nos
        émotions… Alors pourquoi ne pas réunir dans un seul endroit les
        activités qui nous permettent justement d’accomplir tout ça ?
      </Text>
      <Text sx={{ mb: 3 }}>
        C’est avec cette envie de promouvoir le bien-être mental dans un seul
        lieu qu’elle décide de rejoindre la couveuse du Crips IdF en 2019 et de
        développer cette idée!
      </Text>
      <Text sx={{ mb: [5, 7] }}>
        18 mois, 3 intervenant.e.s bénévoles et 1 pandémie plus tard, l’idée se
        transforme en réalité avec <strong>mindkit !</strong>
      </Text>
      <Heading sx={{ mb: 3 }}>Qui sommes-nous ?</Heading>
      <Text sx={{ mb: 3 }}>
        Une équipe de bénévoles hyper motivée qui a une seule envie : promouvoir
        l’importance de booster sa forme mentale !{' '}
        <span role="img" aria-label="strength and brain emojis">
          💪🧠
        </span>{' '}
      </Text>
      <Text sx={{ mb: [5, 7] }}>
        La mission de notre asso’ ? Rendre accessible à tou.te.s, et plus
        particulièrement aux jeunes & jeunes LGBTQI+ / femmes & femmes
        transgenres en situation de précarité les activités telles que le
        fitness, le yoga + la méditation & les thérapies de type brèves.
      </Text>
      <Heading sx={{ mb: 3 }}>Que faisons-nous concrètement ?</Heading>
      <Text sx={{ mb: 3 }}>
        Nous offrons des activités mindkit auprès des assoces qui soutiennent le
        public que nous aimerions toucher :{' '}
        <a
          href="https://www.le-refuge.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Le Refuge
        </a>{' '}
        et à{' '}
        <a
          href="https://emmaus-france.org/lespace-femme-de-lagora-deux-heures-pour-elles/"
          target="_blank"
          rel="noopener noreferrer"
        >
          l'espace femme l'Agora d'Emmaüs
        </a>{' '}
        à Paris.{' '}
      </Text>
      <Text sx={{ mb: 3 }}>
        Ainsi, nous proposons différents ateliers pour booster la forme mentale
        : yoga + méditation, des ateliers d’écriture et des séances de
        photolangage ! Nous aimerions dans un futur proche proposer aussi des
        séances de fitness.
      </Text>
      <Text sx={{ mb: 3 }}>
        Quant à notre site, nous avons réuni des liens de :
      </Text>
      <Box as="ul" sx={{ mb: [5, 7] }}>
        <li>
          Fitness, yoga et méditation : des applis et des vidéos
          pré-enregistrées sur YouTube et Facebook pour que tu fasses une
          activité physique tranquillement de chez toi.
        </li>
        <li>
          Soutien psychologique gratuits et développement personnel : des liens
          d’organismes proposant des séances de soutien gratuits mais aussi des
          articles sur comment mieux gérer son stress et les émotions.
        </li>
      </Box>
      <Heading sx={{ mb: 3 }}>Que faisons-nous pour le grand public ?</Heading>
      <Text sx={{ mb: 3 }}>
        A bas le stress ! Nous avons ouvert un{' '}
        <a
          href="https://www.meetup.com/mindkit-team-anti-stress-paris/"
          target="_blank"
          rel="noopener noreferrer"
        >
          groupe mindkit sur meetup
        </a>{' '}
        afin de proposer des activités du mental fitness. 👌
      </Text>
      <Text sx={{ mb: 3 }}>
        Le prix de ses séances nous aidera en partie à subventionner nos
        interventions auprès des assoces qui soutiennent des causes qui nous
        tiennent à coeur.
      </Text>
      <Text sx={{ mb: 3 }}>
        Nous voulons ainsi créer un cercle vertueux et solidaire qui nous
        permettra d’entretenir notre forme mentale en même temps qu'aider ceux /
        celles dans une situation de précarité à en faire de même !
      </Text>
      <Text sx={{ mb: [5, 7] }}>
        Rejoins nos activités mindkit solidaires{' '}
        <a
          href="https://www.meetup.com/mindkit-team-anti-stress-paris/"
          target="_blank"
          rel="noopener noreferrer"
        >
          ici
        </a>{' '}
        !
      </Text>
      <Heading sx={{ mb: 3 }}>Contacte-nous</Heading>
      <Text>
        As-tu des questions, des suggestions ? Ou simplement tu veux nous
        envoyer un petit mot ? Alors suis-nous sur nos réseaux !{' '}
        <Styled.a
          href="https://twitter.com/_mindkit"
          target="_blank"
          rel="noopener noreferrer"
        >
          Twitter
        </Styled.a>{' '}
        /{' '}
        <Styled.a
          href="https://www.instagram.com/_mindkit/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </Styled.a>{' '}
        ou envoie nous un email à{' '}
        <Styled.a href="mailto:hello.mindkit@gmail.com">
          hello.mindkit@gmail.com
        </Styled.a>
      </Text>
    </Box>
    <Box
      sx={{
        gridColumnStart: [1],
        gridColumnEnd: [2, 3, 5, 13],
        color: 'black',
        mt: 6,
      }}
    >
      <Heading sx={{ variant: 'text.heading', fontSize: 5, mb: 5 }}>
        Qui est la team mindkit
      </Heading>
      <Grid columns={[1, 2, 3]} gap={5}>
        {data.allSanityTeam.nodes.map(node => (
          <Box mb={[3, 5]}>
            {node.image && (
              <GatsbyImage
                alt={`Photo of ${node.name}`}
                image={node.image.asset.gatsbyImageData}
                sx={{ mb: 3, maxWidth: '100%', borderRadius: 1 }}
              />
            )}
            <Heading as="h3" sx={{ fontWeight: 'bold', fontSize: 4, mb: 2 }}>
              {node.name}
            </Heading>
            <TeamBlockContent blocks={node._rawBio} />
          </Box>
        ))}
      </Grid>
    </Box>
    <Box
      sx={{
        gridColumnStart: [1, null, 2, 4],
        gridColumnEnd: [2, 3, 4, 10],
        textAlign: 'center',
      }}
    >
      <Heading sx={{ mb: 3 }}>Nos partenaires</Heading>
      <Flex sx={{ justifyContent: 'center' }}>
        {data.logos.nodes.map(logo => (
          <GatsbyImage
            alt=""
            image={logo.childImageSharp.gatsbyImageData}
            sx={{ m: 2 }}
          />
        ))}
      </Flex>
    </Box>
  </Layout>
);

export const query = graphql`
  query AboutPageQuery {
    # aboutImageOne: file(relativePath: { eq: "about-1.jpg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 500) {
    #       ...GatsbyImageSharpFluid_noBase64
    #     }
    #   }
    # }
    # aboutImageTwo: file(relativePath: { eq: "about-2.jpg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 500) {
    #       ...GatsbyImageSharpFluid_noBase64
    #     }
    #   }
    # }
    # aboutImageThree: file(relativePath: { eq: "about-3.jpg" }) {
    #   childImageSharp {
    #     fluid(maxWidth: 500) {
    #       ...GatsbyImageSharpFluid_noBase64
    #     }
    #   }
    # }
    logos: allFile(
      filter: { relativePath: { in: ["crips-idf.jpg", "le-refuge.png"] } }
    ) {
      nodes {
        childImageSharp {
          gatsbyImageData(height: 140)
        }
      }
    }
    allSanityTeam(sort: { fields: priority, order: ASC }) {
      nodes {
        name
        image {
          asset {
            gatsbyImageData(width: 600, layout: CONSTRAINED)
          }
        }
        _rawBio
      }
    }
  }
`;

export default AboutPage;
